/* eslint-disable import/no-cycle */
import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Row from '../row';

const ContainerBox = styled(Box)(() => ({
  flexGrow: 1,
  padding: '50px 0',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  position: 'relative',
  zIndex: 2,
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: -1
  }
}));

export default function ContainerCmp({ data }) {
  return (
    <ContainerBox
      sx={{
        backgroundColor: data?.backgroundColor || 'transparent',
        backgroundImage: data?.backgroundImage ? `url(${data.backgroundImage.file.url})` : null,
        '&::before': {
          backgroundColor: data?.backgroundImageOverlayColor || 'transparent',
          opacity: data?.backgroundImageOverlayOpacity
            ? data.backgroundImageOverlayOpacity / 100 : 1
        }
      }}
    >
      <Grid container alignContent='center'>
        {
          data.rows?.map(row => <Row row={row} key={row.id} />)
        }
      </Grid>
    </ContainerBox>
  );
}
