/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
import React from 'react';
import { Card, CardContent, Grid, styled, Box } from '@mui/material';
import DynamicComponent from '../../utils/dynamic-component';

export default function Column({ data }) {
  const ColumnContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    height: '100%',
    '& .card': {
      height: '100%',
      boxShadow: '2px 2px 7px #d6d6d6',
      wordBreak: 'break-all',
      '&:hover': {
        boxShadow: 'none'
      }
    },
    '& .baseColumn': {
      [theme.breakpoints.down('xs')]: {
      margin:'24px 0px'
      },
      // wordBreak: 'break-all',
      backgroundColor: data?.backgroundColor ? data.backgroundColor : 'transparent',
      // boxShadow: '2px 2px 7px #d6d6d6',
      '&:hover': {
        boxShadow: data?.hasHoverEffect ? '0px 0px 8px 2px rgb(176 176 176 / 70%)' : 'none'
        // cursor: 'pointer'
      },
      height: '100%'
    }
  }));

  return (

    <Grid item alignContent='flex-start' justifyContent='center' sm={data.columnSize || 12} xs={12} height='auto'>
      <ColumnContainer>
        <Box className='baseColumn'>
          {/* Card Version */}
          {data?.isCard && (
            <Card className='card'>
              <CardContent>
                {DynamicComponent(
                  data.component?.__typename,
                  Object.assign(data.component, {
                    textColor: data.textColor,
                    alignText: data.alignText
                  })
                )}
              </CardContent>
            </Card>
          )}

          {/* Without Card */}
          {!data?.isCard && (
            DynamicComponent(
              data.component?.__typename,
              Object.assign(data.component, {
                textColor: data.textColor,
                alignText: data.alignText
              })
            )
          )}
        </Box>
      </ColumnContainer>
    </Grid>

  );
}
