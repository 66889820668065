import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RichText from '../richtext/index';

export default function Card({ data }) {
  return (
    <Grid item>
      {data.richText && data.richText.raw && <RichText data={data} />}
    </Grid>
  );
}
