import * as React from 'react';
import Box from '@mui/material/Box';
import ImageDisplay from '../image-display';

function ImageOnly({ data }) {
  return (
    <Box>
      <ImageDisplay data={data.image} />
    </Box>
  );
}

export default ImageOnly;
