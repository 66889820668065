import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
// import TableRow from '@mui/core/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const TableComponentContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '& .boxContainer': {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#ffffff !important'
    // padding: '50px 0 !important',
    // marginTop: '16px'
  },
  '& .h5': {
    color: '#383838 !important',
    fontWeight: 'bold !important'
  },
  '& .MainContainer': {
    padding: '50px 0'
  }
}));

export default function TableComponent({ data }) {
  return (
    <TableComponentContainer>
      <Box className='boxContainer'>
        <Grid container>
          <Grid item xs={12}>
            {/* <Item> */}
            {data.tableHeader || data.tableRows
              ? (
                <div>
                  {/* <Typography variant='h3' className='h5'>{data.entryTitle ? data.entryTitle.toUpperCase() : 'TABLE'}</Typography>
                    <Typography variant='headingdividerline' /> */}
                  <TableContainer component={Paper}>
                    <Table className='table' size='large'>
                      {data.tableHeader && data.tableHeader.length
                        && (
                          <TableHead>
                            <TableRow scope='row'>
                              {data.tableHeader.map(cell => <TableCell><b>{cell}</b></TableCell>)}
                            </TableRow>
                          </TableHead>
                        )}
                      {data.tableRows && data.tableRows.length
                        && (
                          <TableBody>
                            {data.tableRows.map(row => (
                              <TableRow hover key={row?.id} scope='row'>
                                {/* <TableCell>{row?.entryTitle}</TableCell> */}
                                {row?.cellValues.map(cell => <TableCell>{cell}</TableCell>)}
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                    </Table>
                  </TableContainer>
                </div>
              ) : null}
            {/* </Item> */}
          </Grid>
        </Grid>
      </Box>
    </TableComponentContainer>
  );
}
