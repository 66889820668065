/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Slider from 'react-slick';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { Link } from 'gatsby';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { typeToPageMap } from '../../utils/constants';

const CarouselContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  '& a': {
    color: '#000',
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'none'
    }
  },
  [theme.breakpoints.up('md')]: {
    boxShadow: 'none',
    backgroundColor: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: '10px',
    backgroundColor: '#fff'
  },
  ...theme.typography.body2,
  '& .dots': {
    '& li.slick-active button::before': {
      color: '#E95525'
    },
    '& li': {
      '& button::before': {
        fontSize: '14px',
        color: '#747474'
      }
    }
  },
  '& .maintitle': {
    color: '#383838 !important',
    fontWeight: 'bold !important',
    marginTop: '30px !important',
    marginLeft: '100px !important'
  },
  '& .title': {
    color: '#383838 !important',
    fontWeight: 'bold !important'
  },
  '& .carddim': {
    padding: '0px !important',
    boxShadow: 'none',
    border: 'none',
    cursor: 'auto'
  },
  '& .gridcontent': {
    justifyContent: 'center'
  },
  '& .divider': {
    color: '#383838 !important',
    fontWeight: 'bold !important',
    marginLeft: '100px !important',
    marginBottom: '30px !important',
    borderColor: 'rgb(0 0 0) !important',
    background: 'none !important',
    borderBottomWidth: '2px !important',
    width: '42px !important'
  },
  '& .listButtonStyle': {
    padding: '2px !important',
    minWidth: '0px !important',
    color: '#383838',
    backgroundColor: 'inherit',
    border: 'none'
  },
  '& .listIconStyle': {
    fontSize: '16px !important',
    color: '#383838 !important'
  },
  '& .listIconStyle1': {
    fontSize: '11px !important',
    color: '#383838 !important'
  },
  '& .assetCardActionButton1': {
    backgroundColor: '#020249 !important',
    color: '#FFFFFF !important',
    marginRight: '5px !important',
    fontSize: '16px !important',
    border: '0px !important'
  },
  '& .containerMain': {
    [theme.breakpoints.up('xs')]: {
      padding: '0px'
    }
  },
  ' & .slick-next': {
    right: '-33px'
  },
  '& .carouselGrid': {
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
      minHeight: '270px'
    },
    [theme.breakpoints.down('xl')]: {
      height: 'auto',
      minHeight: '350px'
    }
  }
}));

export default function Carousel({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots dots',
    prevArrow: <ArrowBackIosIcon />,
    nextArrow: <ArrowForwardIosIcon />
  };
  return (
    <CarouselContainer>
      <Box p={{ xs: 0 }}>
        <Slider {...settings}>
          {data?.slides?.map(val => {
            const buttonTypeName = val?.button?.link?.refPage?.__typename;
            const slideTypeName = val?.slideLink?.link?.refPage?.__typename;
            return (
              <Container className='containerMain'>
                <Card variant='outlined' className='carddim'>
                  <Grid container flexWrap={{ xs: 'wrap-reverse', sm: 'wrap', md: 'wrap', lg: 'wrap', xl: 'wrap' }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      p={{ xs: '15px', sm: '15px', md: '0 45px 0px 75px', lg: '0 45px 0px 75px', xl: '0 45px 0px 75px' }}
                      className='carouselGrid'
                    >
                      <Typography variant='body2' color='inherit' component='div' float='left' paddingTop='30px'>
                        {val.category}
                      </Typography>
                      {/* If Button Link exists --> show button (check if internal or external)
                          else check for Slide Link --> show only title with link to internal or external page
                          else display only title without clickable.
                      */}
                      {val?.button?.link
                        ? (
                          <Typography component='div'>
                            <Typography variant='h6' color='inherit' component='div' float='left' className='title'>
                              {val.title}
                            </Typography>
                            <Typography variant='body2' color='inherit' component='div' marginBottom='15px'>
                              {val.description.description}
                            </Typography>
                            <Typography variant='body2' color='inherit' component='div'>
                              {val?.date && <CalendarTodayOutlinedIcon className='listIconStyle1' fontSize='small' />}
                              {' '}
                              {val?.date}
                            </Typography>
                            <Typography variant='body2' color='inherit' component='div' marginBottom='15px'>
                              { val?.location && <LocationOnIcon className='listIconStyle1' fontSize='small' /> }
                              {val?.location}
                            </Typography>
                            {/* External Button Link */}
                            {val?.button?.link?.url ? (
                              val?.button?.link?.url?.url && val?.button?.link?.title && (
                                <Button href={val.button.link.url.url} target={val?.button?.link?.openInANewTab ? '_blank' : ''} variant='contained' className='assetCardActionButton1'>
                                  {val.button.link.title}
                                </Button>
                              )
                            ) : (
                              // Internal Button Link
                              val?.button?.link?.refPage && val?.button?.link?.refPage?.slug && val?.button?.link?.entryTitle && (
                                <Button href={typeToPageMap[buttonTypeName] + val.button.link.refPage.slug} target={val?.button?.link?.openInANewTab ? '_blank' : ''} variant='contained' className='assetCardActionButton1'>
                                  {val.button.link.entryTitle}
                                </Button>
                              )
                            )}
                          </Typography>
                        )
                        : (
                          val?.slideLink && val?.slideLink?.link)
                          ? (
                            <>
                              <Typography>
                                {/* External Slide Link */}
                                {val?.slideLink?.link?.url ? (
                                  val?.slideLink?.link?.title && (
                                    <Link to={val.slideLink.link.url.url} target={val?.slideLink?.link?.openInANewTab ? '_blank' : ''}>
                                      <Typography variant='h6' color='inherit' component='div' float='left' className='title'>
                                        {val.slideLink.link.title}
                                      </Typography>
                                    </Link>
                                  )
                                )
                                  : (
                                  //  Internal Slide Link
                                    val?.slideLink?.link?.refPage && val?.slideLink?.link?.refPage?.slug && val?.slideLink?.link?.entryTitle && (
                                      <Link to={typeToPageMap[slideTypeName] + val.slideLink.link.refPage.slug} target={val?.slideLink?.link?.openInANewTab ? '_blank' : ''}>
                                        <Typography variant='h6' color='inherit' component='div' float='left' className='title'>
                                          {val.slideLink.link.entryTitle}
                                        </Typography>
                                      </Link>
                                    )
                                  )}
                              </Typography>
                              <Typography variant='body2' color='inherit' component='div' marginBottom='15px'>
                                {val.description.description}
                              </Typography>
                              <Typography variant='body2' color='inherit' component='div'>
                                {val?.date && <CalendarTodayOutlinedIcon className='listIconStyle1' fontSize='small' />}
                                {' '}
                                {val?.date}
                              </Typography>
                              <Typography variant='body2' color='inherit' component='div' marginBottom='15px'>
                                {val?.location && <LocationOnIcon className='listIconStyle1' fontSize='small' />}
                                {val?.location}
                              </Typography>
                            </>
                          )
                          : (
                            <>
                              <Typography variant='h6' color='inherit' component='div' float='left' className='title'>
                                {val.title}
                              </Typography>
                              <Typography variant='body2' color='inherit' component='div' marginBottom='15px'>
                                {val.description.description}
                              </Typography>
                              <Typography variant='body2' color='inherit' component='div'>
                                {val?.date && <CalendarTodayOutlinedIcon className='listIconStyle1' fontSize='small' />}
                                {' '}
                                {val?.date}
                              </Typography>
                              <Typography variant='body2' color='inherit' component='div' marginBottom='15px'>
                                {val?.location && <LocationOnIcon className='listIconStyle1' fontSize='small' />}
                                {val?.location}
                              </Typography>

                            </>
                          )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className='gridcontent'
                    >
                      <GatsbyImage image={getImage(val.image)} />
                    </Grid>
                  </Grid>
                </Card>
              </Container>

            );
          })}
        </Slider>
      </Box>
    </CarouselContainer>
  );
}
