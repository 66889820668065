import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { typeToPageMap } from '../../utils/constants';

const IndexHeroContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '& .BoxBackground': {
    flexGrow: 1
  },
  '& .MainContainer': {
    padding: '50px 0 70px'
  },
  '& .headerText': {
    padding: '24px 0 5px 0'
  },
  '& .ParagraphText': {
    marginBottom: '30px!important'
  },
  '& .ParagraphText1': {
    marginBottom: '10px!important',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px !important'
    }
  },
  '& .website': {
    textTransform: 'none!important'
  }
}));

function HeroBanner({ data }) {
  const pageTypeName = data?.button?.link?.refPage?.__typename;
  return (
    <IndexHeroContainer>
      <Box className='BoxBackground'>
        <Grid container spacing={2} className='MainContainer'>
          <Grid item xs={12}>
            <Typography variant='heroheading' className='headerText' sx={{ color: data.textColor }}>
              {data.heading}
            </Typography>
            {data?.content?.raw && (
              <Typography sx={{ color: data.textColor }}>
                {' '}
                {documentToReactComponents(JSON.parse(data?.content?.raw))}
              </Typography>
            )}
            {data?.button?.link && data?.button?.link?.url ? (
              data?.button?.link?.url?.url
              && data?.button?.link?.title && (
                <Link href={data.button.link.url.url} target={data?.button?.link?.openInANewTab ? '_blank' : ''}>
                  <Button size='small' variant='contained' className='assetCardActionButton1'>{data?.button?.link?.title}</Button>
                </Link>
              )
            ) : (
              data?.button?.link?.refPage && data?.button?.link?.refPage?.slug && data?.button?.link?.entryTitle
              && (
                <Link href={typeToPageMap[pageTypeName] + data.button.link.refPage.slug} get={data?.button?.link?.openInANewTab ? '_blank' : ''}>
                  <Button size='small' variant='contained' className='assetCardActionButton1'>{data?.button?.link?.entryTitle}</Button>
                </Link>
              )
            )}
          </Grid>
        </Grid>
      </Box>
    </IndexHeroContainer>
  );
}

export default HeroBanner;
