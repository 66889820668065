import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  color: theme.palette.text.secondary,
  backgroundColor: '#fff',
  boxShadow: 'none',
  '& .accordion': {
    boxShadow: '0px 0px 5px 1px #00000029',
    border: '1px solid #DBDBDB'
  }
}));

const AccordianContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '& .boxContainer': {
    flexGrow: 1,
    marginTop: '16px'
  },
  '& .h5': {
    color: '#383838 !important',
    fontWeight: 'bold !important'
  },
  '& .MainContainer': {
    padding: '50px 0'
  }
}));

export default function Accordian({ data }) {
  return (
    <AccordianContainer>
      <Box className='boxContainer'>
        <Grid container>
          <Grid item xs={12}>
            {/* <Typography variant='h3' className='h5'>{data.accordianTitle.toUpperCase()}</Typography> */}
            {/* <Typography variant='headingdividerline' /> */}
          </Grid>
          <Grid item xs={12}>
            <Item>
              {data.accordianItems.map(item => (
                <Accordion key={item.id} className='accordion'>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography><b>{item.accordianHeader}</b></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {documentToReactComponents(JSON.parse(item.accordianBody.raw))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </AccordianContainer>
  );
}
