/* eslint-disable import/no-cycle */
import React from 'react';
import Accordion from '../components/accordian';
import ContainerCmp from '../components/container';
import Row from '../components/row';
import Column from '../components/column';
import TableComponent from '../components/table';
import Stepper from '../components/stepper';
import Card from '../components/card';
import Carousel from '../components/carousel';
import HeroBanner from '../components/banner';
import ImageOnly from '../components/image-only';

const ComponentsMap = {
  ContentfulCompAccordianContainer: Accordion,
  ContentfulCompContainer: ContainerCmp,
  ContentfulCompRow: Row,
  ContentfulCompColumn: Column,
  ContentfulCompTable: TableComponent,
  ContentfulCompStepperContainer: Stepper,
  ContentfulCompRichTextBlock: Card,
  ContentfulRanCompCarousel: Carousel,
  ContentfulCompRichTextWithButton: HeroBanner,
  ContentfulCompImageOnly: ImageOnly
};

export default function DynamicComponent(component, data) {
  if (typeof ComponentsMap[component] !== 'undefined') {
    return React.createElement(ComponentsMap[component], {
      component,
      data
    });
  }
  // return React.createElement(NoComponent, {
  //   component
  // });
}
