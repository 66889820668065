import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TimelineOppositeContent } from '@mui/lab';
import Button from '@mui/material/Button';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { iconFileTypesMap, mapIconBasedOnFileType, typeToPageMap } from '../../utils/constants';

const StepperContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '& .buttonLink': {
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
    fontWeight: 'normal',
    color: '#030330',
    '&:hover': {
      border: 'none',
      color: 'initial',
      backgroundColor: 'transparent',
      fontWeight: 'normal'
    }
  },
  '& .headingdividerline': {
    borderBottom: '4px solid #383838',
    width: '42px',
    display: 'block',
    margin: '18.5px 0',
    borderRadius: '2px'
  },
  '& .timeLineItem': {
    marginLeft: '-3px'
  },
  '& .progressNumber': {
    width: '14px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#383838',
    padding: '10px',
    margin: '0px'
  },
  '& .timeLineConnector': {
    backgroundColor: '#383838',
    margin: '0px !important'
  },
  '& .heading': {
    fontWeight: 'bold',
    marginBottom: 0
  },
  '& .stepperButton': {
    margin: '0px'
  }
}));
export default function Stepper({ data }) {
  return (
    <StepperContainer>
      <Timeline align='left' sx={{ p: 0 }}>
        <TimelineItem className='timeLineItem'>
          <TimelineOppositeContent style={{ flex: 0 }} sx={{ p: 0 }} />
          {data.stepperNumber && (
            <TimelineSeparator>
              <TimelineDot className='progressNumber'>{data.stepperNumber}</TimelineDot>
              <TimelineConnector className='timeLineConnector' sx={{ mr: 1 }} />
            </TimelineSeparator>
          )}
          <TimelineContent>
            <Box>
              <Typography gutterBottom variant='h5' component='div' className='heading'>{data?.stepperTitle.toUpperCase()}</Typography>
              <Typography variant='headingdividerline' />
            </Box>
          </TimelineContent>
        </TimelineItem>
        {data?.stepperItems.map(stepperItem => {
          const pageTypeName = stepperItem?.stepperButton?.link?.refPage?.__typename;
          return (
            <TimelineItem>
              <TimelineOppositeContent style={{ flex: 0 }} sx={{ p: 0 }} />
              <TimelineSeparator sx={{ pr: 1.5, pl: 0.4 }}>
                <TimelineDot className='timeLineConnector' />
                <TimelineConnector className='timeLineConnector' />
              </TimelineSeparator>
              <TimelineContent sx={{ pr: 0 }}>
                <Grid container spacing={1}>
                  <Grid
                    xs={12}
                    sm={12}
                    lg={9}
                    container
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent='flex-start'
                    alignItems='flex-stretch'
                    flexWrap='initial'
                    paddingBottom={3}
                  >
                    {stepperItem.stepperImage && (
                      <Grid xs={12} lg={3} style={{ borderRadius: '5px' }}>
                        <GatsbyImage image={getImage(stepperItem.stepperImage)} />
                      </Grid>
                    )}
                    <Grid xs={12} lg={9} paddingLeft={{ xs: 0, sm: 2 }} pt={{ xs: '20px', md: '0px' }}>
                      <Typography gutterBottom variant='h5' component='div' className='heading'>{stepperItem?.stepperHeader}</Typography>
                      <Typography variant='body1' component='div' gutterBottom>
                        {documentToReactComponents(JSON.parse(stepperItem.stepperBody.raw))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md={3}
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
                    flexWrap='initial'
                    pt={{ xs: 0, md: 5 }}
                    mb={{ xs: 4, md: 0 }}
                    pl={{ xs: 0, sm: 3 }}
                  >
                    {
                      stepperItem?.stepperButton?.link && stepperItem?.stepperButton?.link?.url ? (
                        stepperItem?.stepperButton?.link?.url?.url && stepperItem?.stepperButton?.link?.title
                    && (<Button className='stepperButton' href={stepperItem?.stepperButton?.link?.url?.url} target={stepperItem?.stepperButton?.link?.openInANewTab ? '_blank' : ''} sx={{ mr: 6 }} variant='contained'>{stepperItem?.stepperButton?.link?.title}</Button>)
                      ) : (
                        stepperItem?.stepperButton?.link?.refPage && stepperItem?.stepperButton?.link?.refPage?.slug && stepperItem?.stepperButton?.link?.entryTitle && (
                          <Button className='stepperButton' href={typeToPageMap[pageTypeName] + stepperItem.stepperButton.link.refPage.slug} target={stepperItem?.stepperButton?.link?.openInANewTab ? '_blank' : ''} sx={{ mr: 6 }} variant='contained'>{stepperItem?.stepperButton?.link?.entryTitle}</Button>)
                      )
                    }
                    {stepperItem?.stepperAssets?.map(asset => {
                      const iconData = mapIconBasedOnFileType(asset);
                      return (
                        <a
                          className='stepperButton'
                          href={asset?.downloadLink?.file?.url}
                          download={asset?.downloadLink?.file?.fileName}
                          target={asset?.openInANewTab ? '_blank' : ''}
                          rel='noreferrer'
                          key={stepperItem.id}
                        >
                          <Button
                            sx={{ pr: 0 }}
                            text-aling='right'
                            className='buttonLink'
                            variant='text'
                            startIcon={iconData && iconData.icon && iconFileTypesMap[iconData.icon] !== undefined && iconFileTypesMap[iconData.icon] !== '' ? <iconData.DynamicIcon /> : <DownloadForOfflineIcon />}
                          >
                            {asset?.title}
                          </Button>
                        </a>
                      );
                    })}
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </StepperContainer>
  );
}
