/* eslint-disable import/no-cycle */
import React from 'react';
import { Grid, Container, styled, Box } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
// import { useStyles } from './styles';
// import { styled } from '@mui/material/styles';

import Column from '../column';

const alignItemsMap = {
  Top: 'flex-start',
  Center: 'center',
  Bottom: 'flex-end',
  Stretch: 'stretch'
};

const justifyContentMap = {
  Start: 'flex-start',
  Center: 'center',
  End: 'flex-end',
  'Space Around': 'space-around',
  'Space Between': 'space-between',
  'Space Evenly': 'space-evenly'
};

function Row({ row }) {
  const RowContainer = styled(Box)(({ theme }) => ({
    '& .baseRow': {
      textAlign: row.alignText,
      paddingLeft: row.leftPadding !== -1 ? `${row?.leftPadding}px` : '10px',
      paddingRight: row.rightPadding !== -1 ? `${row?.rightPadding}px` : '10px',
      paddingTop: row.topPadding !== -1 ? `${row?.topPadding}px` : '10px',
      paddingBottom: row.bottomPadding !== -1 ? `${row?.bottomPadding}px` : '10px',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0px',
        paddingRight: '0px'
      }
    },
    '& .headerText': {
      padding: '24px 0 5px 0'
    },
    '& .h3': {
      color: '#383838 !important',
      fontWeight: 'bold !important'
    }
  }));
  // const classes = useStyles(row);
  return (
    <Container>
      <RowContainer id={row?.anchorLinkId || null}>
        <Grid
          item
          spacing={1}
          xs={12}
        >
          <Grid
            container
            alignItems={alignItemsMap[row.alignItems] || 'center'}
            justifyContent={justifyContentMap[row.justifyContent] || 'flex-start'}
            spacing={row.spacing || 2}
          >
            {row.title && (
              <Grid item xs={12}>
                <Box>
                  <Typography gutterBottom variant='h3' component='div' className='h3' sx={{ color: '#000' }}>
                    {row.title}
                  </Typography>
                  <Typography variant='headingdividerline' />
                </Box>
              </Grid>
            )}
            {row.columns && row.columns.length && row.columns
              .map(column => (
                <Column
                  key={column.id}
                  data={Object.assign(column, { alignText: row.alignText })}
                />
              ))}
          </Grid>
        </Grid>
      </RowContainer>
    </Container>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    // entryTitle: PropTypes.string,
    title: PropTypes.string,
    anchorLinkId: PropTypes.string.isRequired,
    justifyContent: PropTypes.string.isRequired,
    alignItems: PropTypes.string.isRequired,
    spacing: PropTypes.string.isRequired,
    columns: PropTypes.string.isRequired,
    alignText: PropTypes.string.isRequired,
    leftPadding: PropTypes.string.isRequired,
    rightPadding: PropTypes.string.isRequired,
    topPadding: PropTypes.string.isRequired,
    bottomPadding: PropTypes.string.isRequired
  })
};

Row.defaultProps = {
  row: null
};

export default Row;
